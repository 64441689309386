import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import Truncate from 'react-truncate';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { AngularContext } from 'react-app';

import t from 'react-translate';
import useMentoringProgramRole from 'athena/hooks/use-mentoring-program-role';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import { MentoringProgramEnrollment } from 'redux/schemas/models/mentoring-program-enrollments';

// Styles
import { css } from '@emotion/core';
import { black, gray2, gray6, hexToRgbaString, white } from 'styles/global_defaults/colors';
import { screenMdMin } from 'styles/global_defaults/media-queries';
import { almostBlack, novoEdBlue, oasis } from 'athena/styles/colors';
import {
  semiBoldFontWeight,
  textLargeFontSize,
  textMediumLineHeight,
  textSmallFontSize,
  textSmallLineHeight,
} from 'styles/global_defaults/fonts';
import {
  extraLargeSpacing,
  largeSpacing,
  quarterSpacing,
  standardSpacing,
  tripleSpacing,
} from 'styles/global_defaults/scaffolding';

// Components
import NvIcon from 'shared/components/nv-icon';
import MentoringProgramContext from 'athena/components/mentoring-program/context';
import AutoMatchingMenu from '../program-header-menu/auto-matching-menu';
import WorkspaceTitle from './workspace-title';
import HeaderMainContent from './header-main-content';
import getProgramDatesComponent from './program-dates';
import { config } from '@config/pendo.config.json';
import moment from 'moment';

const getCompactHeaderStyles = (isTablet) => css`
  position: fixed;
  top: 0;
  left: ${isTablet ? 0 : extraLargeSpacing}px;
  right: 0;
  z-index: 1;
  height: ${tripleSpacing}px;
  border-bottom: none;
`;

const getTopBarBgColor = (isTablet, showCompactHeader) => {
  if (showCompactHeader) return white;
  if (isTablet) return hexToRgbaString(oasis, 0.1);
  return 'transparent';
};

const getStyles = ({
  showBackMyMentees,
  showCompactHeader,
  isTablet,
  scrollPercentage,
  isAdmin,
}) => css`
  height: ${350 + (showBackMyMentees ? standardSpacing : 0)}px;
  background-color: ${showCompactHeader ? white : hexToRgbaString(oasis, 0.1)};
  border-bottom: ${quarterSpacing}px solid ${oasis};
  ${showCompactHeader && !isAdmin && getCompactHeaderStyles(isTablet)}

  .top-bar {
    height: ${tripleSpacing}px;
    position: ${isTablet ? 'fixed' : 'sticky'};
    top: 0;
    left: 0;
    border-bottom: ${showCompactHeader ? `1px solid ${gray6}` : 'none'};
    padding: ${isAdmin ? '25px 20px' : '0'};
    ${showCompactHeader && isAdmin && isTablet && 'padding-left: 50px;'}
    background-color: ${getTopBarBgColor(isTablet, showCompactHeader)};

    .date-container {
      flex: 1;
      opacity: 0.8;
      color: ${almostBlack};
    }
  }

  .main {
    .main-description {
      opacity: 0.8;
      color: ${almostBlack};
    }
    opacity: ${1 - (scrollPercentage / 100)};
  }

  .secondary {
    bottom: ${tripleSpacing}px;
    opacity: ${scrollPercentage / 100};

    .secondary-name {
      flex: 1;
    }
  }

  .mentoring-program-picture {
    bottom: 80px;
    right: ${isTablet ? -standardSpacing : '70'}px;
    ${isAdmin && `z-index: -1;`}
  }

  .menu-items {
    gap: ${standardSpacing}px;

    ${isTablet && showBackMyMentees && `
      padding-left: ${largeSpacing}px;
    `}

    ${!isAdmin && `
      flex-direction: column;
      gap: 0;
      align-items: center;
      justify-content: center;

      padding-left: ${isTablet ? tripleSpacing : largeSpacing}px;
      .program-name {
        font-size: ${textSmallFontSize}px;
        color: ${gray2};
        font-weight: ${semiBoldFontWeight};
        padding-bottom: ${quarterSpacing}px;
        line-height: ${textSmallLineHeight}px;
      }
      .workspace-title, .workspace-title-link {
        width: 100% !important;
        font-size: ${textLargeFontSize}px;
        line-height: ${textMediumLineHeight}px;
        color: ${black};
        padding-top: 0 !important;
      }
    `}
  }

  .link {
    color: ${novoEdBlue};
    font-weight: ${semiBoldFontWeight};
  }
`;



const getSettingsBtnStyles = (isTablet) => css`
  z-index: ${isTablet ? 1 : 'unset'};
`;

type ProgramHeaderProps = {
  scrollPercentage: number;
  showGoBack?: boolean;
};

const ProgramHeader = ({ scrollPercentage, showGoBack }: ProgramHeaderProps) => {
  const { isLoading, mentoringProgram } = useContext(MentoringProgramContext);
  const { name, releasedDate, endDate, mentorshipProgramEnrollment: enrollmentId, matchAnnouncementDate } = mentoringProgram || {};
  const { isMentor, isMentee, isAdmin } = useMentoringProgramRole();
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(state => (
    getMentoringProgramEnrollment(state, enrollmentId) || {}
  ));
  const { assignedConnections, user: currentUser } = mentorshipProgramEnrollment || {}

  const connectionId = parseInt($state.params.connectionId, 10);
  const connection = assignedConnections?.find(connec => connec.connectionId === connectionId) || assignedConnections?.[0];
  const connectionUser = connection?.assignedEnrollment?.user;

  const hasMultipleMentees = assignedConnections?.length > 1;
  const showBackMyMentees = showGoBack && isMentor && hasMultipleMentees;
  const isParticipant = (isMentee || isMentor) && showGoBack;
  const isTablet = useMediaQuery({ query: `(max-width: ${screenMdMin}px)` });
  const showCompactHeader = showGoBack && (
    scrollPercentage >= 100
    || (isParticipant && scrollPercentage >= 50)
  );

  const styles = getStyles({
    showBackMyMentees,
    showCompactHeader,
    isTablet,
    scrollPercentage,
    isAdmin,
  });
  const settingsBtnStyles = getSettingsBtnStyles(isTablet);

  const dataQATag = config.pendo.athena.mentorshipProgram;

  const renderProgramDates = useCallback(() => (
    releasedDate || endDate
      ? getProgramDatesComponent(releasedDate, endDate)
      : (
        <Link
          to='/settings?tab=program'
          className='link bold text-large-body'
          data-qa={dataQATag.programHeader.addProgramDatesLink}
        >
          {t.MENTORING_PROGRAMS.ADD_PROGRAM_DATES()}
        </Link>
      )
  ), [mentoringProgram]);

  const headerClasses = useMemo(() => (showCompactHeader ? '' : 'pl-4 pr-4'), [showCompactHeader]);

  const isMatchDateFuture = moment(matchAnnouncementDate).isAfter(moment());

  const topNav = useMemo(() => document.getElementById('top-nav'), []);

  useEffect(() => {
    if (showCompactHeader) {
      topNav?.classList.add('athena-compact-header');
      if (isParticipant) {
        topNav?.classList.add('athena-compact-header-participant');
      }
    } else {
      topNav?.classList.remove('athena-compact-header', 'athena-compact-header-participant');
    }
  }, [showCompactHeader]);

  return (
    <div css={styles} className={headerClasses}>
      <div className='position-relative h-100'>
        <div className={`d-flex top-bar w-100${showCompactHeader ? ' athena-compact-header' : ''}`}>
          {showCompactHeader && (
            <div className={`d-flex align-items-center menu-items ${isParticipant ? 'w-100' : 'w-50'}`}>
              <Truncate lines={1} ellipsis='...' className='heading-4 w-100 program-name'>
                {name}
              </Truncate>
              {isParticipant && connectionUser && !isMatchDateFuture && (
                <WorkspaceTitle
                  currentUser={currentUser}
                  connectionUser={connectionUser}
                  showGoBack={showBackMyMentees}
                />
              )}
            </div>
          )}
          {isAdmin && (
            <div className={`d-flex align-items-center menu-items justify-content-end ${showCompactHeader ? 'w-50' : 'w-100'}`}>
              <AutoMatchingMenu />
              <Link to='/settings' css={settingsBtnStyles} data-qa={dataQATag.settings.link}>
                <NvIcon icon='settings' size='smallest' />
              </Link>
            </div>
          )}
        </div>
        {!isLoading && !showCompactHeader && (
          <HeaderMainContent
            mentoringProgram={mentoringProgram}
            renderProgramDates={renderProgramDates}
            showBackMyMentees={showBackMyMentees}
            currentUser={currentUser}
            connectionUser={connectionUser}
            isParticipant={isParticipant}
          />
        )}
      </div>
    </div>
  );
};

export default ProgramHeader;
