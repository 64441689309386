import React, { Fragment, useContext, useState } from 'react';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import t from 'react-translate';
import { almostWhite } from 'athena/styles/colors';
import { useAppDispatch } from 'redux/store';
import { wrapThunkAction } from 'redux/utils';
import { addAlertMessage } from 'redux/actions/alert-messages';
import { createMentoringProgramEnrollmentsFromCSV, validateMentoringProgramEnrollmentsCSV } from 'redux/actions/mentoring-program-enrollments';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import NvDropdown, { NvDropdownOption, NvDropdownButtonStyle, NvDropdownAlign } from 'shared/components/inputs/nv-dropdown';
import NvFilePicker from 'shared/components/nv-filepicker';
import NvIcon from 'shared/components/nv-icon';
import useUploadFile from 'shared/hooks/use-upload-file';
import { S3NameSpaces } from 'shared/services/s3-upload-factory';
import { gray6 } from 'styles/global_defaults/colors';
import { config } from '@config/pendo.config.json';
import MentoringProgramContext from '../context';


type MinimalParticipant = {
  email: string;
  firstName: string;
  lastName: string;
};

type CSVError = {
  error: string;
  row: number;
};

type BulkUploadModalProps = {
  onClose: () => void;
};

const BulkUploadModal = ({
  onClose,
}: BulkUploadModalProps) => {
  const styles = css`
    .user-tab {
      border-top: ${gray6} 1px solid;
      border-bottom: ${gray6} 1px solid;
      cursor: pointer;
    }

    .role-dropdown-button {
      width: 256px;
    }

    .user-list {
      background-color: ${almostWhite};
      border-bottom: ${gray6} 1px solid;
      .flex-fill {
        text-align: left;
        flex: unset !important;
        &:first-of-type {
          width: 250px;
        }
      }
    }

    .error-tab {
      border-top: ${gray6} 1px solid;
      border-bottom: ${gray6} 1px solid;
      cursor: pointer;
    }

    .error-list {
      background-color: ${almostWhite};
      border-bottom: ${gray6} 1px solid;
    }
  `;

  const { mentoringProgram } = useContext(MentoringProgramContext);
  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, mentoringProgram?.id));

  const dispatch = useAppDispatch();
  const { uploadFiles } = useUploadFile();

  const [page, setPage] = useState(1);
  const [fileName, setFileName] = useState('');
  const [courseRole, setCourseRole] = useState<MentoringProgramRole>();
  const [users, setUsers] = useState<MinimalParticipant[]>([]);
  const [usersOpen, setUsersOpen] = useState(false);
  const [errors, setErrors] = useState<CSVError[]>([]);
  const [errorsOpen, setErrorsOpen] = useState(false);

  const onCSVSelected = async (data) => {
    const [novoEdFile] = await uploadFiles(data, `${S3NameSpaces.MENTORSHIP_PROGRAM_ENROLLMENT_CSV}${mentoringProgram.id}`);

    setFileName(`${novoEdFile.uniqueId}-${novoEdFile.name}`);

    wrapThunkAction<{ users: MinimalParticipant[]; errors: CSVError[] }>(dispatch(validateMentoringProgramEnrollmentsCSV({
      programId: mentoringProgram.id,
      fileName: `${novoEdFile.uniqueId}-${novoEdFile.name}`,
    }))).then(({ payload }) => {
      setErrors(payload.errors);
      setUsers(payload.users);
      setPage(2);
    }).catch(() => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
    });
  };

  const onFileError = () => {
    setUsers([]);
    setUsersOpen(false);
    setErrors([]);
    setErrorsOpen(false);

    dispatch(addAlertMessage({
      type: AlertMessageType.ERROR,
      header: t.FORM.OOPS(),
      message: t.FILE_UPLOAD.NOT_SUPPORTED_ALERT(),
    }));
  };

  const dropdownTitle = () => {
    switch (courseRole) {
      case MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE:
        return t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTEE(mentoringProgramAliases);
      case MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR:
        return t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTOR(mentoringProgramAliases);
      case MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN:
        return t.MENTORING_PROGRAMS.ROLES.ADMIN_CATEGORY.ADMINS();
      default:
        return t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.APPLY_A_ROLE();
    }
  };

  const dataQATag = config.pendo.athena.mentorshipProgram.bulkUploadModal;

  const rolesDropdownButtonItems: NvDropdownOption[] = [
    {
      type: 'accordion-header',
      title: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.TITLE(),
      class: 'font-weight-bold',
      headerClass: 'bg-gray-6',
      items: [
        {
          type: 'radio',
          group: 'role',
          label: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTEE(mentoringProgramAliases),
          isChecked: courseRole === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE,
          callback: () => setCourseRole(MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE),
          dataQa: dataQATag.roleSelect.items.menteeRadioField,
        },
        {
          type: 'radio',
          group: 'role',
          label: t.MENTORING_PROGRAMS.ROLES.PARTICIPANTS_CATEGORY.MENTOR(mentoringProgramAliases),
          isChecked: courseRole === MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR,
          callback: () => setCourseRole(MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR),
          dataQa: dataQATag.roleSelect.items.mentorRadioField,
        },
      ],
    },
    {
      type: 'accordion-header',
      title: t.MENTORING_PROGRAMS.ROLES.ADMIN_CATEGORY.TITLE(),
      class: 'font-weight-bold',
      headerClass: 'bg-gray-6',
      items: [
        {
          type: 'radio',
          group: 'role',
          label: t.MENTORING_PROGRAMS.ROLES.ADMIN_CATEGORY.ADMINS(),
          isChecked: courseRole === MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN,
          callback: () => setCourseRole(MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN),
          dataQa: dataQATag.roleSelect.items.adminRadioField,
        },
      ],
    },
  ];

  const confirmOnClick = () => {
    wrapThunkAction(dispatch(createMentoringProgramEnrollmentsFromCSV({
      programId: mentoringProgram.id,
      fileName,
      courseRoleName: courseRole,
    }))).catch(() => {
      dispatch(addAlertMessage({
        type: AlertMessageType.ERROR,
        header: t.FORM.OOPS(),
        message: t.FORM.ERROR_SOMETHING_WRONG(),
      }));
    });

    onClose();
  };

  return (
    <div css={styles} className='d-flex flex-column align-items-center'>
      {page === 1 ? (
        <Fragment>
          <div>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.DESCRIPTION()}</div>
          <div className='font-weight-bold w-100 mt-7'>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NOTE()}</div>
          <ul>
            <li>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NOTE_1()}</li>
            <li>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NOTE_2()}</li>
            <li>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NOTE_3()}</li>
          </ul>
          <div className='d-flex justify-content-center mt-8'>
            <Button
              size='lg'
              href={`/mentorship_programs/${mentoringProgram.id}/mentorship_program_enrollment_management/download_csv_template`}
              target='_blank'
              variant='secondary'
              className='mr-2'
              data-qa={dataQATag.downloadTemplate}
              data-qa-id={`${dataQATag.downloadTemplate}_${mentoringProgram?.id}`}
            >
              {t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.DOWNLOAD_TEMPLATE()}
            </Button>
            <NvFilePicker
              multiple={false}
              accept={['.csv', 'text/csv']}
              onChange={(e) => onCSVSelected(e)}
              onSelectError={onFileError}
              data-qa={dataQATag.uploadCsv}
              data-qa-id={`${dataQATag.uploadCsv}_${mentoringProgram?.id}`}
            >
              <Button size='lg' variant='primary'>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.UPLOAD_CSV()}</Button>
            </NvFilePicker>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <div className='mb-4'>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.PARTICIPANTS_FOUND()}</div>
          <div className='mb-5'>
            <span className='font-weight-bold'>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NOTE()}</span>
            {t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.EXISTING_USERS_NOTE()}
          </div>
          {!!users.length && (
            <div className='w-100'>
              <div className='d-flex justify-content-between align-items-center px-4 user-tab'>
                <div className='d-flex flex-fill py-4' onClick={() => setUsersOpen(!usersOpen)}>
                  <NvIcon icon={usersOpen ? 'arrow-up' : 'arrow-right'} size='smallest' className='mr-3' />
                  <NvIcon icon='profile' size='small' className='text-gray-2 mr-2' />
                  <div className='font-weight-bold mr-auto'>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NUM_PARTICIPANTS(users.length)}</div>
                </div>
                <NvDropdown
                  title={dropdownTitle()}
                  buttonClass='role-dropdown-button'
                  menuClassName='w-100'
                  items={rolesDropdownButtonItems}
                  buttonStyle={NvDropdownButtonStyle.FORM}
                  align={NvDropdownAlign.RIGHT}
                  showRequiredIndicator
                  noMenuPadding
                  toggleDataQa={dataQATag.roleSelect.toggle}
                />
              </div>
              {usersOpen && (
                <div className='px-7 user-list'>
                  {users.map((user) => (
                    <div className='d-flex py-2'>
                      <div className='flex-fill font-weight-bold'>{t.USERS.FULL_NAME(user.firstName, user.lastName)}</div>
                      <div className='flex-fill text-gray-1'>{user.email}</div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          {!!errors.length && (
            <div className='w-100'>
              <div className='d-flex p-4 error-tab' onClick={() => setErrorsOpen(!errorsOpen)}>
                <NvIcon icon={errorsOpen ? 'arrow-up' : 'arrow-right'} size='smallest' className='mr-3' />
                <NvIcon icon='warning' size='small' className='text-danger mr-2' />
                <div className='text-danger font-weight-bold'>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.NUM_ERRORS_FOUND(errors.length, new Set(errors.map(item => item.row)).size)}</div>
              </div>
              {errorsOpen && (
                <div className='px-7 error-list'>
                  <div className='text-gray-2 pt-2 mb-4'>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.YOU_CAN_PROCEED()}</div>
                  {errors.map((err) => (
                    <div className='ml-5 my-2'>
                      <span className='font-weight-bold'>{t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.ROW_NUM(err.row)}</span>
                      <span className='text-danger ml-7'>{err.error}</span>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
          <div className='d-flex justify-content-center mt-6'>
            <NvFilePicker
              multiple={false}
              accept={['.csv', 'text/csv']}
              onChange={(e) => onCSVSelected(e)}
              onSelectError={onFileError}
            >
              <Button
                size='lg'
                variant='secondary'
                className='mr-2'
              >
                {t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.UPLOAD_NEW_CSV()}
              </Button>
            </NvFilePicker>
            <Button
              size='lg'
              onClick={() => confirmOnClick()}
              variant='primary'
              disabled={!courseRole}
              data-qa={dataQATag.confirm}
              data-qa-id={`${dataQATag.confirm}_${mentoringProgram?.id}`}
            >
              {t.MENTORING_PROGRAMS.BULK_UPLOAD_MODAL.CONFIRM()}
            </Button>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default BulkUploadModal;
