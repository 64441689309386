import t from 'react-translate';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import {
  States,
} from 'redux/schemas/models/mentoring-program-enrollments';
import { AngularContext } from 'react-app';
import { config } from '@config/pendo.config.json';
import ProfileModal from './profile-modal';
import RequiredQuestionsModal from '../../required-questions-modal';
import { isAnyRequiredFieldUnanswered, noRequiredQuestions } from '../utils';

type CompleteProfileButtonProps = {
  states: States;
}

const CompleteProfileButton = ({ states }: CompleteProfileButtonProps) => {
  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { showProfile = false } = $state.params || {};
  const [showProfileModal, setShowProfileModal] = useState<boolean>(showProfile);
  const [showRequiredQuestionsModal, setShowRequiredQuestionsModal] = useState<boolean>(false);
  const [remainingQuestions, setRemainingQuestions] = useState(0);
  const buttonRef = useRef(null);

  const getButtonTitle = () => {
    let buttonTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.CTA_BUTTON.EDIT_PROFILE();
    if (noRequiredQuestions(states)) {
      buttonTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.CTA_BUTTON.GET_STARTED();
    }
    if (isAnyRequiredFieldUnanswered(states)) {
      buttonTitle = t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.CTA_BUTTON.CONTINUE();
    }
    return buttonTitle;
  }

  useEffect(() => {
    if (!showProfileModal) {
      buttonRef.current?.blur();
    }
  }, [showProfileModal]);

  return (
    <>
      <Button
        size='lg'
        onClick={() => setShowProfileModal(true)}
        data-qa={config.pendo.athena.mentorshipProgram.participantHome.steps.profile.show}
        ref={buttonRef}
      >
        {getButtonTitle()}
      </Button>
      {showProfileModal && (
        <ProfileModal
          title={showProfile && t.MENTORING_PROGRAMS.PARTICIPANT_HOME.STEPS.PROFILE_MODAL.TITLE.DEFAULT()}
          setShowModal={setShowProfileModal}
          onClose={(remaining) => {
            setRemainingQuestions(remaining);
            setShowRequiredQuestionsModal(true);
          }}
        />
      )}
      {showRequiredQuestionsModal && (
        <RequiredQuestionsModal
          show={showRequiredQuestionsModal}
          remainingQuestions={remainingQuestions}
          onClose={() => setShowRequiredQuestionsModal(false)}
        />
      )}
    </>
  );
};

export default CompleteProfileButton;
