import { css } from '@emotion/react';
import { useContext } from 'react';
import { AngularContext } from 'react-app';
import t from 'react-translate';
import FlyoutProgramCard from 'athena/components/flyout-program-card';
import FlyoutContainer from 'lhs/components/flyout-container';
import { AllLoaded, LoadingBar } from 'lhs/components/loading-placeholders';
import { LoaderType } from 'shared/components/loading-placeholder';
import { useInfiniteLoading } from 'shared/hooks/use-infinite-loading';
import prodPathReplace from 'shared/prod-path-rewrite';
import { halfSpacing } from 'styles/global_defaults/scaffolding';
import { getFlyoutMyPrograms } from 'redux/actions/mentoring-programs';
import { MentoringProgram } from 'redux/schemas/models/mentoring-programs';

const MyProgramsFlyout = () => {
  const styles = css`
    min-height: calc(100vh - 65px);

    img {
      margin-top: auto;
      margin-bottom: 120px;
      margin-right: ${halfSpacing}px;
      align-self: end;
      order: 5;
    }

    .flyout-program-card:last-of-type {
      margin-bottom: 20px;
    }
  `;

  const {
    items,
    noItems,
    loading,
    loadingMore,
    hasMore,
    ids,
  } = useInfiniteLoading(
    'myPrograms',
    'mentoringPrograms',
    { page_size: 20 },
    getFlyoutMyPrograms,
  );

  const programs = items as MentoringProgram[];

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const { programId: currentProgramId } = $state.params;

  return (
    <FlyoutContainer title={t.LHS.MY_PROGRAMS()}>
      <div css={styles} className='d-flex flex-column'>
        {loading && <LoadingBar loaderType={LoaderType.FULL} />}
        {loadingMore && <LoadingBar loaderType={LoaderType.HALF} />}
        {ids.map((id, index) => (
          <FlyoutProgramCard
            programId={id}
            title={programs[id].name}
            enrollmentId={programs[id].mentorshipProgramEnrollment as unknown as number}
            programAlias={programs[id].programAlias}
            releasedDate={programs[id].releasedDate}
            endDate={programs[id].endDate}
            isCurrentProgram={currentProgramId === `${id}`}
          />
        ))}
        <img src={prodPathReplace('images/my-programs-flyout.png')} alt='' />
      </div>
    </FlyoutContainer>
  );
};

export default MyProgramsFlyout;
