import { getSkillTagsFromTaggings } from 'redux/selectors/skills-feedback';
import { getLectureComponent } from 'redux/selectors/lecture-components';
import store from 'redux/store';

export default {
  isRtl,
  swapLeftRight,
  $get: ['Papa', '_', 'humps', function NvUtil(
    Papa,
    _,
    humps,
  ) {
    return {
      isPresent: function isPresent(str) {
        return str !== null && str !== undefined && str !== '';
      },
      // This is not the correct function to obtain the leaderboardPoints of a user
      getCurrentTotalPoints: function getCurrentTotalPoints(totalPoints, releaseDate, decayEnabled, deadlinePassed) {
        if (totalPoints) {
          return totalPoints[0];
        }
        return 0;
      },
      isValidHex: (hexString) => /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hexString),
      stripHtml(stringHtml) {
        return $(stringHtml).text().trim().replaceAll(String.fromCharCode(8203), '');
      },
      hasMeaningfulContent(htmlString) {
        if (!htmlString) {
          return false;
        }

        if (!htmlString.startsWith('<') || !htmlString.endsWith('>')) {
          return true;
        }

        const $html = $(`<div>${htmlString}</div>`);
        return this.stripHtml($html) || $html.find('img').length || $html.find('iframe').length;
      },
      /**
           * Converts a key/value pair of custom downloads dropdown data into translated text.
           * @param {string} key
           * @param {Array} val
           */
      convertToTranslationText(key, val) {
        // Skip translations if the key is a number and just display the value.
        // Currently used for user defined course roles, since we can't translate a user defined
        // role name.
        if (val && !Number.isNaN(parseInt(key, 10))) {
          return val;
        }

        key = humps.decamelize(key);
        return `CUSTOM_DOWNLOADS.DROPDOWN.${key.toUpperCase()}`;
      },
      parseVideoId(url, regex, partToReturn) {
        const matchArr = regex.exec(url);
        // console.log('testing ', regex, ' matches? ', matchArr && matchArr.length);
        if (matchArr && matchArr.length && matchArr.length >= partToReturn) {
          return matchArr[partToReturn];
        }

        return undefined;
      },
      addUserToHeads: (user, heads, activityType) => {
        if (heads) {
          const existingHeadIndex = _.findIndex(heads, (head) => {
            if (activityType && activityType === 'submission') {
              return head.submitter.id === user.id;
            }
            return head.id === user.id;
          });

          heads.splice(existingHeadIndex, 1);

          if (activityType && activityType === 'submission') {
            heads.unshift({
              submitter: user,
            });
          } else {
            heads.unshift(user);
          }
        }
      },

      setCursor: (node, index) => {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(node, index);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
      },
      // content editables only
      placeCaretAtEnd: (el) => {
        el.focus();
        if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
          const range = document.createRange();
          const sel = window.getSelection();
          range.selectNodeContents(el);
          range.collapse(false);
          sel.removeAllRanges();
          sel.addRange(range);
        } else if (typeof document.body.createTextRange !== 'undefined') {
          const textRange = document.body.createTextRange();
          textRange.moveToElementText(el);
          textRange.collapse(false);
          textRange.select();
        }
      },
      selectElementContents: (el) => {
        setTimeout(() => {
          const range = document.createRange();
          range.selectNodeContents(el);
          const sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
        });
      },
      randomPositiveInteger: () => Math.floor((Math.random() * 999999999) + 1),
      randomString,
      /**
      * Allows a user to download a template CSV
      * @param {Array} data an array of arrays
      */
      downloadCSV: (data) => {
        const csv = Papa.unparse(data);
        const csvName = 'template.csv';
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

        if (navigator.msSaveBlob) { // IE 10+
          navigator.msSaveBlob(blob, csvName);
        } else {
          const link = document.createElement('a');
          if (link.download !== undefined) { // feature detection
            // Browsers that support HTML5 download attribute
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', csvName);
            link.style = 'visibility:hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      },
      formatFileSize(size) {
        // round 999 KB to 1 MB
        if (size >= 9990000 && size <= 1000000) {
          return '1 MB';
        }

        // round 999 MB to 1 GB
        if (size >= 9990000000 && size <= 1000000000) {
          return '1 GB';
        }

        let fileSize;
        let suffix;
        let decimals;
        let roundedNum;

        if (Math.floor(size / 1000000000) > 0) {
          fileSize = size / 1000000000;
          suffix = ' GB';
        } else if (Math.floor(size / 1000000) > 0) {
          fileSize = size / 1000000;
          suffix = ' MB';
        } else {
          fileSize = size / 1000;
          suffix = ' KB';
        }

        if (fileSize >= 100) {
          decimals = 0;
          roundedNum = Math.round(fileSize);
        } else if (fileSize >= 10 && fileSize < 100) {
          decimals = 1;
          roundedNum = Math.round(10 * fileSize) / 10;
        } else {
          decimals = 2;
          roundedNum = Math.round(100 * fileSize) / 100;
        }

        return roundedNum.toFixed(decimals) + suffix;
      },
      getFocusableElements($parentElement) {
        if ($parentElement) {
          // focusable elements
          const tabableSelector = 'a[href], area[href], input:not([disabled]), '
          + 'button:not([disabled]),select:not([disabled]), textarea:not([disabled]), '
          + 'iframe, object, embed, *[tabindex]:not([tabindex^="-"]), *[contenteditable=true]';

          return $parentElement?.querySelectorAll(tabableSelector);
        }
        return [];
      },
      focusFirstElement($parentElement, defaultSelector = null) {
        if ($parentElement) {
          setTimeout(() => {
            if (defaultSelector) {
              defaultSelector.focus();
            } else {
              let elements = this.getFocusableElements($parentElement);
              elements = elements ? _.filter(elements, (element) => $(element).is(':visible')) : [];
              if (elements[0]) {
                elements[0].focus();
              }
            }
          });
        }
      },
      checkFocusWithin($parentElement) {
        if ($parentElement) {
          let elements = this.getFocusableElements($parentElement);
          elements = elements ? _.filter(elements, (element) => $(element).is(':visible')) : [];
          elements = elements ? _.filter(elements, (element) => $(element).is(':focus')) : [];
          if (elements.length) {
            return true;
          }
        }
        return false;
      },
      copyToClipboard: function copyToClipboard(value) {
        const textArea = document.createElement('textarea');
        textArea.value = value;
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      },
      isRtl,
      swapLeftRight,
      makeIframesResponsive,
      toSnakeCase,
      wrapStringInDiv,
    };
  }],
};

function isRtl() {
  return document.dir === 'rtl';
}

// in order to support right-to-left languages, we often have to swap the string left and rig
function swapLeftRight(stringInput = '') {
  return stringInput.replace(/\b(?:left|right)\b/gi, (match) => (match === 'left' ? 'right' : 'left'));
}

/* @ngInject */
export function makeIframesResponsive(content) {
  const parseUrlParams = function (src) {
    const source = src;
    const params = {};
    if (source) {
      source
        .replace(/[?&]+([^=&]+)=([^&]*)/gi, (str, key, value) => {
          params[key] = value;
        });
    }
    return params;
  };

  const parsed = $('<div></div>').append(content);
  const DEFAULT_WIDTH = 800;
  const DEFAULT_HEIGHT = 448;

  parsed.find('iframe[class!="not-responsive"]').each(function () {
    const $elem = $(this);
    const params = parseUrlParams(this.src);
    let width = DEFAULT_WIDTH;
    let height = DEFAULT_HEIGHT;

    const parsedWidth = parseInt(params.width, 10);
    const parsedHeight = parseInt(params.height, 10);

    if (!Number.isNaN(parsedWidth) && !Number.isNaN(parsedHeight)) {
      width = parsedWidth;
      height = (width * parsedHeight) / parsedWidth;
    }

    const src = $elem[0]?.attributes?.src?.nodeValue;

    $elem
      .attr('sandbox', (i, val) => {
        // Adding allow-downloads to sandbox attribute to enable download for the embedded files
        if (src?.indexOf('/embeddings') >= 0) {
          return `${val} allow-downloads`;
        }
        return val;
      })
      .attr('width', width)
      .attr('height', height)
      .attr('src', (i, val) => {
        if (val.indexOf('/embeddings') >= 0) {
          const url = new URL(val);
          return `${url.origin}${url.pathname}?width=${width}&height=${height}${params.fixed_height ? '&fixed_height=true' : ''}`;
        }
        return val;
      })
      .wrap(() => (params.fixed_height ? '<div class="fixed_height_iframe"></div>' : '<div class="embed-responsive embed-responsive-16by9"></div>'));
  });

  return parsed;
}

function toSnakeCase(string) {
  return string.replace(/([a-z0-9])([A-Z0-9])/g, '$1_$2').toLowerCase();
}


export const wrapStringInDiv = (str) => `<div>${str}</div>`;


/* @ngInject */
export function randomString(length = 10) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i += 1) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

/* @ngInject */
export function getVerticalCenterStyles(containerHeight, contentHeight) {
  const contentExceedsContainerHeight = contentHeight > containerHeight;

  const top = contentExceedsContainerHeight ? 0 : ((containerHeight - contentHeight) / 2);
  if (top) {
    return {
      top,
      position: 'relative',
    };
  }

  return {};
}

/* @ngInject */
export function getSkillTagsFromAngular(lectureComponentId) {
  const state = store.getState();
  const lectureComponent = getLectureComponent(state, lectureComponentId) || {};
  const { skillTaggings = [] } = lectureComponent;
  const skillTags = getSkillTagsFromTaggings(state, skillTaggings);
  return skillTags;
}

/* @ngInject */
export function getMaximumWordsOfElementById(elementId, maxWords = 10) {
  // Maximum of 10 words by default
  let shortText = '';
  const elementText = document.getElementById(elementId)?.innerText;
  const commentWords = elementText?.split(' ');
  if (!commentWords) {
    return shortText;
  }
  if (commentWords.length <= maxWords) {
    shortText = elementText;
  } else {
    shortText = commentWords[0];
    // eslint-disable-next-line no-plusplus
    for (let index = 1; index < maxWords; index++) {
      shortText = `${shortText} ${commentWords[index]}`;
    }
    shortText = `${shortText} ...`;
  }
  return shortText;
}
