import { css } from '@emotion/react';
import { Fragment, useContext } from 'react';
import { useSelector } from 'react-redux';
import { AngularContext } from 'react-app';
import moment from 'moment';
import t from 'react-translate';
import ClickableContainer from 'components/clickable-container';
import Avatar from 'athena/components/mentoring-program/avatar';
import { menteeBackgroundColor, menteeTextColor, mentorBackgroundColor, mentorTextColor, oasis } from 'athena/styles/colors';
import { gray1, gray5, warning, white } from 'styles/global_defaults/colors';
import { boldFontWeight, semiBoldFontWeight, textXSFontSize } from 'styles/global_defaults/fonts';
import { halfSpacing, quarterSpacing, standardSpacing, threeQuartersSpacing } from 'styles/global_defaults/scaffolding';
import { MentoringProgramEnrollment, MentoringProgramRole } from 'redux/schemas/models/mentoring-program-enrollments';
import { Name } from 'redux/schemas/models/my-account';
import { getMentoringProgramAliases } from 'redux/selectors/mentoring-programs';
import { getMentoringProgramEnrollment } from 'redux/selectors/mentoring-program-enrollments';
import { config } from '@config/pendo.config.json';

type ProgramCardProps = {
  programId: number;
  title: string;
  enrollmentId: number;
  programAlias: { mentee: Name, mentor: Name };
  releasedDate: string;
  endDate: string;
  isCurrentProgram: boolean;
};

const FlyoutProgramCard = ({
  programId,
  title,
  enrollmentId,
  programAlias,
  releasedDate,
  endDate,
  isCurrentProgram,
}: ProgramCardProps) => {
  const ROLE_VALUES = {
    [MentoringProgramRole.MENTORSHIP_PROGRAM_ADMIN]: {
      text: t.MENTORING_PROGRAMS.PROGRAM_ADMIN(),
      textColor: white,
      background: gray1,
    },
    [MentoringProgramRole.MENTORSHIP_PROGRAM_MENTOR]: {
      text: programAlias.mentor?.singularizedTitleized,
      textColor: mentorTextColor,
      background: mentorBackgroundColor,
    },
    [MentoringProgramRole.MENTORSHIP_PROGRAM_MENTEE]: {
      text: programAlias.mentee?.singularizedTitleized,
      textColor: menteeTextColor,
      background: menteeBackgroundColor,
    },
  };

  const PILL_MAX_WIDTH = 146;

  const isClosed = endDate && moment().isAfter(moment(endDate));
  const inProgress = releasedDate && moment().isAfter(moment(releasedDate)) && !isClosed;
  const hasNotStarted = releasedDate && moment(releasedDate).isAfter(moment());

  const mentorshipProgramEnrollment: MentoringProgramEnrollment = useSelector(state => getMentoringProgramEnrollment(state, enrollmentId));
  const { firstConnectionAnnounced } = mentorshipProgramEnrollment;

  const mentoringProgramAliases = useSelector((state) => getMentoringProgramAliases(state, programId));

  const { injectServices } = useContext(AngularContext);
  const [$state] = injectServices(['$state']);
  const handleClick = () => {
    if (mentorshipProgramEnrollment.assignedConnections.length > 1) {
      $state.go('mentoring-program-my-mentees', { programId });
    } else if (mentorshipProgramEnrollment.assignedConnections.length === 1) {
      $state.go('mentoring-program-single-connection', { programId, connectionId: mentorshipProgramEnrollment.assignedConnections[0].connectionId });
    } else {
      $state.go('mentoring-program-root', { programId });
    }
  };

  const styles = css`
    border-radius: ${halfSpacing}px;
    ${(isClosed || hasNotStarted) && css`
      opacity: 50%;
    `}

    .tab {
      width: ${halfSpacing}px;
      background-color: ${oasis};
      border-top-left-radius: ${halfSpacing}px;
      border-bottom-left-radius: ${halfSpacing}px;
    }

    .role-pill {
      height: ${standardSpacing}px;
      font-weight: ${semiBoldFontWeight};
      border-radius: ${threeQuartersSpacing}px;
      color: ${ROLE_VALUES[mentorshipProgramEnrollment.role.name].textColor};
      background-color: ${ROLE_VALUES[mentorshipProgramEnrollment.role.name].background};
      max-width: ${PILL_MAX_WIDTH}px;

      div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .current-program-badge {
      background-color: ${warning};
      color: ${white};
      position: absolute;
      left: -${threeQuartersSpacing}px;
      top: -1px;
      box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
      font-size: ${textXSFontSize}px;
      padding: 2px ${halfSpacing}px;
      height: 16px;
      font-weight: ${boldFontWeight};
      max-width: 200px;

      &:before {
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 ${quarterSpacing}px ${quarterSpacing}px 0;
        border-color: transparent ${gray5} transparent transparent;
        bottom: -${quarterSpacing}px;
        left: 0;
      }
        }
  `;

  return (
    <div css={styles} className='d-flex mx-4 mt-4 border border-gray-6 flyout-program-card'>
      <ClickableContainer
        onClick={handleClick}
        className='w-100'
        data-qa={config.pendo.athena.mentorshipProgram.flyoutProgramCard}
        data-qa-id={`${config.pendo.athena.mentorshipProgram.flyoutProgramCard}_${programId}`}
      >
        <div className='tab' />
        <div className='py-4 pl-4 pr-2 w-100 position-relative'>
          {isCurrentProgram && (
            <div className='current-program-badge ellipsis'>
              {t.MENTORING_PROGRAMS.MY_PROGRAMS_FLYOUT.CURRENT_PROGRAM()}
            </div>
          )}
          <div className='d-flex justify-content-between align-items-center'>
            <div className='role-pill d-flex align-items-center pl-2 pr-2 text-small'>
              <div>{ROLE_VALUES[mentorshipProgramEnrollment.role.name].text}</div>
            </div>
            {isClosed && <div className='text-small'>{t.MENTORING_PROGRAMS.MY_PROGRAMS_FLYOUT.CLOSED()}</div>}
            {inProgress && <div className='text-small'>{t.MENTORING_PROGRAMS.MY_PROGRAMS_FLYOUT.STARTED(moment(releasedDate).format('L'))}</div>}
            {hasNotStarted && <div className='text-small'>{t.MENTORING_PROGRAMS.MY_PROGRAMS_FLYOUT.STARTS(moment(releasedDate).format('L'))}</div>}
          </div>
          <div className='heading-4 mt-1'>{title}</div>
          {firstConnectionAnnounced && (
            <Fragment>
              {mentorshipProgramEnrollment.assignedConnections.length === 1 && (
                <div className='d-flex align-items-center mt-2'>
                  <Avatar user={mentorshipProgramEnrollment.assignedConnections[0].assignedEnrollment.user} className='mr-1' />
                  {t.USERS.FULL_NAME(
                    mentorshipProgramEnrollment.assignedConnections[0].assignedEnrollment.user.firstName,
                    mentorshipProgramEnrollment.assignedConnections[0].assignedEnrollment.user.lastName,
                  )}
                </div>
              )}
              {mentorshipProgramEnrollment.assignedConnections.length > 1 && (
                <div className='mt-2'>{t.MENTORING_PROGRAMS.MY_PROGRAMS_FLYOUT.NUM_OF_MENTEES({ ...mentoringProgramAliases, menteesCount: mentorshipProgramEnrollment.assignedConnections.length })}</div>
              )}
            </Fragment>
          )}
        </div>
      </ClickableContainer>
    </div>
  );
};

export default FlyoutProgramCard;
